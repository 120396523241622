import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { Link } from "@reach/router"

class BlogGridCard extends PureComponent {
  static propTypes = {
    imgUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    authorName: PropTypes.string,
  }

  render() {
    const { imgUrl, title, subTitle, authorName, publishBio, UID } = this.props

    const backgroundImgStyle = {
      backgroundImage: `url(${imgUrl})`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      paddingBottom: "50%",
    }

    return (
      <div className="grid-item">
        <div className="blog-post-card">
          <Link
            to={UID ? `/${UID}/` : `/${slugify(title.toLowerCase())}/`}
            className="blog-post-card-thumb"
          >
            <div
              className="blog-post-card-thumb-frame"
              style={backgroundImgStyle}
            ></div>
          </Link>

          <div className="blog-post-card-content blog-post-card-content--grid">
            {publishBio && (
              <Link
                to={`/${slugify(authorName.toLowerCase())}/`}
                className="blog-post-card-author"
              >
                written by <span>{authorName}</span>
              </Link>
            )}
            {!publishBio && (
              <div className="blog-post-card-author">
                written by <span>{authorName}</span>
              </div>
            )}

            <h6>
              <Link to={UID ? `/${UID}/` : `/${slugify(title.toLowerCase())}/`}>
                {title}
              </Link>
            </h6>
            <p className="blog-post-card__sub-content">{subTitle}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default BlogGridCard
