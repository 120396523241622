import React, { Component } from "react"
import { navigate } from "@reach/router"
import slugify from "slugify"
import * as _ from "lodash"
import MediaQuery from "react-responsive"

class BlogSidebar extends Component {
  transformedName
  searchQuery
  searchRef = React.createRef()

  onCategoryClick(name, pageNumber) {
    if (name === "All") {
      this.transformedName = "null"
    } else {
      this.transformedName = slugify(name, {
        replacement: "-",
        lower: true,
        strict: true,
      })
    }

    if (!this.searchQuery) {
      navigate(`?category=${this.transformedName}&page=1`)
    } else {
      navigate(`?category=${this.transformedName}&page=1&q=${this.searchQuery}`)
    }
  }

  onSearchChange(search) {
    this.searchQuery = search.trim()

    if (this.transformedName) {
      navigate(`?category=${this.transformedName}&page=1&q=${this.searchQuery}`)
    } else {
      navigate(`?category=null&page=1&q=${this.searchQuery}`)
    }
  }

  handleKeyDown = (event, val) => {
    if (event.key === "Enter") {
      this.onSearchChange(event.target.value)
    }
  }

  render() {
    const { categories, page, category } = this.props

    let allCategories = []
    categories.forEach(({ node }) => {
      node.articles.forEach(article => {
        allCategories.push(article)
      })
    })
    return (
      <div className="blog-room-sidebar">
        <div className="blog-room-sidebar-inner" data-sidebar="true">
          <div className="form-search">
            <input
              type="search"
              className="form-control"
              placeholder="I'm looking for..."
              onKeyDown={(event, val) => this.handleKeyDown(event, val)}
              ref={this.searchRef}
            />
            <button
              type="submit"
              onClick={() => this.onSearchChange(this.searchRef.current.value)}
            >
              <i className="far fa-search"></i>
            </button>
          </div>
          <MediaQuery minWidth="1023px">
            <ul className="blog-room-filter">
              <li
                onClick={() => this.onCategoryClick("null", page)}
                className={category === "" ? "active" : ""}
                key={"0"}
              >
                <span className="text">All</span>
                <span className="count">{allCategories.length}</span>
              </li>
              {categories.map(({ node }, index) => {
                if (node.articles.length > 0) {
                  return (
                    <li
                      onClick={() => this.onCategoryClick(node.name, page)}
                      className={
                        node.name === _.startCase(category) ? "active" : ""
                      }
                      key={index}
                    >
                      <span className="text">{node.name}</span>
                      <span className="count">{node.articles.length}</span>
                    </li>
                  )
                } else {
                  return null
                }
              })}
            </ul>
          </MediaQuery>
          <MediaQuery maxWidth="1023px">
            <div className="blog-room-filter-dropdown-select">
              <select
                className="blog-room-filter-dropdown"
                onChange={event => this.onCategoryClick(event.target.value)}
              >
                <option className={category === "" ? "active" : ""} key={"0"}>
                  All
                </option>
                {categories.map(({ node }, index) => {
                  if (node.articles.length > 0) {
                    return (
                      <option
                        className={
                          node.name === _.startCase(category) ? "active" : ""
                        }
                        key={index}
                      >
                        {node.name}
                      </option>
                    )
                  } else {
                    return null
                  }
                })}
              </select>
            </div>
          </MediaQuery>
        </div>
      </div>
    )
  }
}

export default BlogSidebar
