import React from "react"
import BlogGridCard from "../BlogGridCard"
import GridPagination from "../../grid-pagination"
import { gql, useQuery } from "@apollo/client"

let hasResults
let hasAggregate

const ArticlePaginationGrid = ({
  allArticles,
  category,
  page,
  search,
  categories,
}) => {
  const GET_STRAPI_ARTICLES = gql`
    query allStrapiArticles($name: String!, $start: Int!) {
      articlesConnection(
        start: $start
        limit: 6
        sort: "updated_at:desc"
        where: { category: { name_contains: $name } }
      ) {
        values {
          id
          Title
          Description
          thumbnail {
            url
          }
          author {
            firstName
            lastName
            publishBio
          }
          category {
            name
          }
          UID
          razaroon {
            name
            title
            razaroonImage {
              url
            }
          }
        }
        aggregate {
          count
          totalCount
        }
      }
    }
  `

  const GET_STRAPI_ARTICLES_SEARCH = gql`
    query allStrapiArticlesSearch($name: String!, $start: Int!, $q: String) {
      articlesConnection(
        start: $start
        limit: 6
        sort: "updated_at:desc"
        where: { _q: $q, category: { name_contains: $name } }
      ) {
        values {
          id
          Title
          Description
          thumbnail {
            url
          }
          author {
            firstName
            lastName
            publishBio
          }
          category {
            name
          }
          UID
          razaroon {
            name
            title
            razaroonImage {
              url
            }
          }
        }
        aggregate {
          count
          totalCount
        }
      }
    }
  `

  function determineStart(page) {
    return (page - 1) * 6
  }

  const { loading, error, data } = useQuery(GET_STRAPI_ARTICLES, {
    variables: {
      name: category ? category.replace(/-/g, " ") : "",
      start: determineStart(page),
    },
  })

  const alternateData = useQuery(GET_STRAPI_ARTICLES_SEARCH, {
    variables: {
      name: category ? category.replace(/-/g, " ") : "",
      start: determineStart(page),
      q: search,
    },
  }).data

  hasResults = alternateData
    ? alternateData.articlesConnection.values
    : data
    ? data.articlesConnection.values
    : []

  hasAggregate = alternateData
    ? alternateData.articlesConnection.aggregate
    : data
    ? data.articlesConnection.aggregate
    : []

  return (
    <div>
      <div className="grid grid--blog grid-1 grid-sm-2 grid-lg-3 grid-small-articles">
        {hasResults.length > 0 ? (
          hasResults.map((node, i) => {
            return (
              <BlogGridCard
                imgUrl={
                  node["razaroon"] && node["razaroon"]["razaroonImage"]
                    ? node["razaroon"]["razaroonImage"]["url"]
                    : node["thumbnail"]["url"]
                }
                subTitle={node["Description"]}
                title={node["Title"]}
                authorName={`${node["author"]["firstName"]} ${node["author"]["lastName"]}`}
                publishBio={node["author"]["publishBio"]}
                key={i}
                UID={node["UID"]}
              />
            )
          })
        ) : (
          <div className="grid-item">
            <h3>No Results... But you're still awesome though</h3>
          </div>
        )}
      </div>
      <GridPagination
        categories={categories}
        category={category}
        page={page}
        search={search}
        aggregate={hasAggregate}
      />
    </div>
  )
}

export default ArticlePaginationGrid
