import React from "react"
import { navigate } from "@reach/router"
import * as _ from "lodash"

const GridPagination = ({ category, page, search, aggregate }) => {
  let articlesTotal = aggregate.count
  let totalPages = calculatePagesTotal(articlesTotal)
  let middlePage = determineMiddlePage(page)
  let lastPage = isLastPage(middlePage) ? Number(middlePage) + 1 : null
  let firstPage = page < 2 ? 1 : Number(middlePage) - 1

  function determineMiddlePage(page) {
    if (isMiddlePage(_.startCase(category), page)) {
      if (page > 1) {
        return page
      } else {
        return Number(page) + 1
      }
    }
    return null
  }

  // logic to modify the route for the razroo website
  function onPageClick(pageNumber) {
    if (!search) {
      navigate(`?category=${category}&page=${pageNumber}`)
    } else {
      navigate(`?category=${category}&page=${pageNumber}&q=${search}`)
    }
  }

  function calculatePagesTotal(articlesTotal) {
    return Math.ceil(articlesTotal / 6)
  }

  function isMiddlePage(category, page) {
    if (totalPages < 2) {
      return totalPages - Number(page) > 0
    } else {
      return true
    }
  }

  function isLastPage(middlePage) {
    if (middlePage) {
      return totalPages - Number(middlePage) > 0
    }
  }

  function goBackAPage(pageNumber) {
    if (Number(pageNumber) < 2) {
      return
    }
    let internalPageNumber = Number(pageNumber) - 1
    navigate(`?category=${category}&page=${internalPageNumber}&q=${search}`)
  }

  function goForwardAPage(pageNumber) {
    if (Number(pageNumber) === totalPages) {
      return
    }
    let internalPageNumber = Number(pageNumber) + 1
    navigate(`?category=${category}&page=${internalPageNumber}&q=${search}`)
  }

  return (
    <div className="pagination">
      <ul>
        <li
          onClick={() => goBackAPage(page)}
          className={page < 2 ? "disabled" : ""}
        >
          <div className="pagination-link pagination-btn">Previous</div>
        </li>

        <li
          onClick={() => onPageClick(firstPage)}
          className={`first-page ${
            firstPage.toString() === page ? "active" : ""
          }`}
        >
          <div className="pagination-link">{firstPage}</div>
        </li>

        {middlePage && (
          <li
            onClick={() => onPageClick(middlePage)}
            className={`middle-page ${
              middlePage.toString() === page ? "active" : ""
            }`}
          >
            <div className="pagination-link">{middlePage}</div>
          </li>
        )}

        {lastPage && (
          <li
            onClick={() => onPageClick(lastPage)}
            className={`last-page ${
              lastPage.toString() === page ? "active" : ""
            }`}
          >
            <div className="pagination-link">{lastPage}</div>
          </li>
        )}

        <li
          onClick={() => goForwardAPage(page)}
          className={page === totalPages.toString() ? "disabled" : ""}
        >
          <div href="#" className="pagination-btn pagination-link">
            Next
          </div>
        </li>
      </ul>
    </div>
  )
}

export default GridPagination
