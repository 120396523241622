import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import SEO from "../components/seo"
import BlogSidebar from "../components/blogSidebar"
import ArticlePaginationGrid from "../components/global/ArticlePaginationGrid"
import MostRecentPosts from "../components/global/MostRecentPosts"
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = ({ location }) => {
  const filterQuery =
    (location.state && location.state.query) || location.href || ""

  let params = filterQuery ? new URL(filterQuery).searchParams : ""
  let category =
    params && params.get("category") !== "null" ? params.get("category") : ""
  let page = params && params.get("page") ? params.get("page") : "1"
  let search = params && params.get("q") ? params.get("q") : ""
  let darkMode =
    typeof window !== `undefined`
      ? window.localStorage.getItem("darkMode")
      : null

  const articlesAndCategoriesData = useStaticQuery(graphql`
    query articlesPaginationQueryAndAllCategories {
      allStrapiArticles(limit: 9, sort: { fields: updated_at, order: DESC }) {
        edges {
          node {
            id
            Title
            Description
            thumbnail {
              localFile {
                publicURL
              }
            }
            author {
              firstName
              lastName
              publishBio
            }
          }
        }
      }

      allStrapiCategories(sort: { order: ASC, fields: name }) {
        edges {
          node {
            name
            articles {
              id
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      bodyTheme={darkMode === "true" ? "theme-blog-dark" : "theme-blog-light"}
    >
      <SEO
        title="Razroo Blog"
        description="Razroo Articles on Angular and Lean Creativity!"
        keywords={[
          `angular`,
          `typescript`,
          `razroo`,
          `articles`,
          `lean creativity`,
        ]}
      />
      <header>
        <Menu darkModeToggle="true" darkMode={darkMode === "true"} />
      </header>
      <main className="page-wrapper">
        <section className="section section-first">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-room" data-sidebar-anchor="true">
                  <BlogSidebar
                    categories={
                      articlesAndCategoriesData["allStrapiCategories"]["edges"]
                    }
                    page={page}
                    category={category}
                  />
                  <div className="blog-room-list">
                    <ArticlePaginationGrid
                      allArticles={
                        articlesAndCategoriesData["allStrapiArticles"]["edges"]
                      }
                      page={page}
                      category={category}
                      search={search}
                    />
                    <MostRecentPosts />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </Layout>
  )
}

export default IndexPage
