import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@reach/router"
import slugify from "slugify"

const MostRecentPosts = () => {
  const data = useStaticQuery(graphql`
    query TwoRecentArticlesQuery {
      allStrapiArticles(limit: 2, sort: { fields: created_at, order: DESC }) {
        edges {
          node {
            id
            Title
            Description
            author {
              firstName
              lastName
            }
          }
        }
      }
    }
  `)

  return (
    <div className="blog-room-recent">
      <h6 className="blog-room-title">Most recent posts</h6>
      <div className="grid grid-1 grid-sm-2 grid-small-articles">
        {data["allStrapiArticles"]["edges"].slice(0, 3).map(({ node }, i) => {
          return (
            <div className="grid-item" key={i}>
              <div className="blog-post-card-recent">
                <div className="blog-post-card-content">
                  <a href="#" className="blog-post-card-author">
                    written by{" "}
                    <span>{`${node["author"]["firstName"]} ${node["author"]["lastName"]}`}</span>
                  </a>
                  <h6>
                    <Link to={slugify(node["Title"].toLowerCase())}>
                      {node["Title"]}
                    </Link>
                  </h6>
                  <p>{node["Description"]}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MostRecentPosts
